import { TransformType } from '@mr-yum/cdn-image'
import { CdnImage, GuestImage } from 'lib/gql'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import React, { useState } from 'react'
import { cloudflareLoader } from 'utils/cloudflareLoader'

interface ImageProps extends Omit<NextImageProps, 'src' | 'srcSet'> {
  image: GuestImage | Pick<CdnImage, 'id' | 'originalImageUrl'>

  transform?: TransformType
}

export const Image = ({
  image,
  objectFit,
  layout,
  transform,
  ...rest
}: ImageProps) => {
  const [hasError, setHasError] = useState(false)

  return (
    <NextImage
      src={image.originalImageUrl}
      loader={(loaderOptions) =>
        cloudflareLoader({
          loaderOptions,
          transform,
          hasError,
        })
      }
      objectFit={objectFit}
      layout={layout}
      onError={() => {
        setHasError(true)
      }}
      {...rest}
    />
  )
}
