import React from 'react'

export const SvgImage = ({ svg }: { svg: string }) => {
  if (!svg) return null

  return (
    <img
      className="h-full w-full"
      src={`data:image/svg+xml;utf8,${encodeURIComponent(svg)}`}
    />
  )
}
