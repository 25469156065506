import { ChevronRightIcon, cn } from '@mr-yum/frontend-ui'
import { IconCard } from 'components/Common/IconCard'
import { SvgImage } from 'components/Common/SvgImage'
import { ProgramPayload } from 'lib/gql'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface Image {
  as: string
  src: string
}

const LogoImage = ({ image }: { image: Image }) => {
  switch (image.as) {
    case 'svg':
      return <SvgImage svg={image.src} />
    case 'img':
      return <img src={image.src} />
    default:
      return null
  }
}

const backgroundImage = (image: Image) => {
  switch (image.as) {
    case 'svg':
      return `url("data:image/svg+xml;utf8,${encodeURIComponent(image.src)}")`
    case 'img':
      return `url(${image.src})`
    default:
      return 'none'
  }
}

const transformDescription = (
  description: string,
  replacements: Map<string, string>,
) => {
  return Array.from(replacements.entries()).reduce((prev, [key, value]) => {
    return prev.replace(new RegExp(`%{${key}}`, 'g'), value)
  }, description)
}

export const RewardMembershipCTA = ({
  href,
  venueName,
  program,
  cta,
  onClick,
}: {
  href?: string
  venueName?: string
  program: Pick<ProgramPayload, 'id' | 'name' | 'logo' | 'configuration'>
  cta: string
  onClick: () => void
}) => {
  const { background, logo, description, action, textColor } =
    program.configuration.cta[cta]
  const replacements = new Map<string, string>(
    venueName ? [['venueName', venueName]] : [],
  )

  return (
    <IconCard
      style={{
        ...(background
          ? {
              background: backgroundImage(background),
            }
          : {}),
        ...(textColor ? { color: textColor } : {}),
      }}
      icon={logo ? <LogoImage image={logo} /> : null}
      description={
        <p className={cn({ 'pl-16': !logo })}>
          <ReactMarkdown
            allowedTypes={['text', 'paragraph', 'emphasis', 'strong', 'link']}
            unwrapDisallowed
            linkTarget="_blank"
            renderers={{
              paragraph: ({ children }) => (
                <p className="text-inherit">{children}</p>
              ),
              link: ({ children, target, href }) => (
                <a
                  className="text-inherit underline"
                  target={target}
                  href={href}
                >
                  {children}
                </a>
              ),
            }}
          >
            {transformDescription(description, replacements)}
          </ReactMarkdown>
        </p>
      }
      showIndicator={true}
      indicatorIcon={<ChevronRightIcon className="self-center" />}
      onClick={onClick}
      data-testid="reward-membership-cta"
      {...(action
        ? {
            href: action,
            target: '_blank',
            rel: 'noreferrer',
          }
        : {
            href,
          })}
    />
  )
}
