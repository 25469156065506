import { Location } from 'lib/gql'
import React from 'react'

interface AddressProps {
  location?: Partial<Location> | null
  className?: string
}

export const Address = ({ location, className }: AddressProps) => {
  if (!location) return null

  return (
    <p className={className}>
      {location?.unitNumber ? `Unit ${location.unitNumber} / ` : ''}
      {location?.formattedAddress}
    </p>
  )
}
