import {
  DrawerContainer,
  ModalContainer,
  useBreakpoint,
} from '@mr-yum/frontend-ui'
import React, { ComponentProps, ReactNode } from 'react'

type ResponsiveModalProps = {
  children: ReactNode
  onClose: () => void
  drawerProps?: Partial<ComponentProps<typeof DrawerContainer>>
  modalProps?: Partial<ComponentProps<typeof ModalContainer>>
}

export const ResponsiveModal = ({
  children,
  onClose,
  drawerProps,
  modalProps,
}: ResponsiveModalProps) => {
  const breakpoint = useBreakpoint()
  const isMobile = ['sm', 'md'].includes(breakpoint)

  if (isMobile) {
    return (
      <DrawerContainer
        anchor="bottom"
        isOpen={!!children}
        onClose={onClose}
        {...drawerProps}
      >
        <div className="flex flex-col overflow-hidden rounded-t-xl">
          {children}
        </div>
      </DrawerContainer>
    )
  }

  return (
    <ModalContainer isOpen={!!children} onClose={onClose} {...modalProps}>
      <div className="w-[calc(100vw-32px)] max-w-[500px] overflow-hidden rounded">
        {children}
      </div>
    </ModalContainer>
  )
}
