import {
  ChevronRightIcon,
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
} from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React, { ReactNode } from 'react'

interface Props {
  heading: ReactNode
  description?: ReactNode
  icon?: ReactNode
  showIndicator?: boolean
  indicatorIcon?: ReactNode
}

type AnchorProps<C extends React.ElementType = 'a'> =
  PolymorphicComponentPropsWithRef<C, Props>

export const IconCard = React.forwardRef(
  <C extends React.ElementType = 'a'>(
    {
      icon,
      as,
      heading,
      isLoading,
      description,
      showIndicator = true,
      indicatorIcon = <ChevronRightIcon />,
      onClick,
      ...props
    }: AnchorProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = as || 'a'

    return (
      <Component
        ref={ref}
        className={cn(
          'relative flex w-full justify-between gap-3 rounded bg-interactive-surface py-3 pl-3 text-left text-foreground transition-colors hover:bg-interactive-surface-hover focus-visible:outline-none',
          {
            'items-center': !icon,
            'items-start': icon,
            'pointer-events-none opacity-60': isLoading,
            'pr-2': showIndicator,
            'pr-4': !showIndicator,
          },
        )}
        disabled={isLoading}
        onClick={onClick}
        {...props}
      >
        <div
          className={cn('flex justify-center space-x-4', {
            'items-center': !description,
            'items-start': description,
          })}
        >
          {icon && (
            <div className="my-1 flex h-12 w-12 shrink-0 items-center justify-center overflow-hidden rounded text-foreground">
              {icon}
            </div>
          )}

          <div
            className={cn('space-y-1 py-1', {
              'pl-1': !icon,
            })}
          >
            <h4 className="my-label-sm">{heading}</h4>
            {description && <p className="my-body-sm">{description}</p>}
          </div>
        </div>

        {showIndicator && indicatorIcon && (
          <div className="flex h-12 shrink-0 items-center">{indicatorIcon}</div>
        )}
      </Component>
    )
  },
)
