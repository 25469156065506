import { ArrowLeftIcon, CloseIcon, IconButton } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'

interface Props {
  title?: React.ReactNode
  showBack?: boolean
  isCloseable?: boolean
  onClose: () => void
}

export const Sheet = ({
  title,
  children,
  showBack = true,
  isCloseable,
  onClose,
}: PropsWithChildren<Props>) => {
  const { back } = useRouter()

  return (
    <div data-testid="sheet" className="bg-surface text-foreground">
      <header
        className={cn('relative flex items-center justify-center px-14 py-4 ', {
          'border-b': title,
        })}
      >
        {showBack && (
          <IconButton
            onClick={() => back()}
            className="absolute left-2 hover:bg-interactive-surface-hover"
          >
            <ArrowLeftIcon className="mx-auto inline-block" />
          </IconButton>
        )}

        {title && <h1 className="my-label-md">{title}</h1>}

        {isCloseable && (
          <IconButton
            onClick={onClose}
            className="absolute right-2 hover:bg-interactive-surface-hover"
          >
            <CloseIcon className="mx-auto inline-block" />
          </IconButton>
        )}
      </header>

      <div className="pb-[calc(2*env(safe-area-inset-bottom))]">{children}</div>
    </div>
  )
}
